@import "./pf4-imports";
@import "./Spacer";

#root {
  height: 100vh;
}

.pf-u-h-100 {
  height: 100% !important;
}
.pf-u-min-h-100 {
  min-height: 100%;
}
.pf-m-width-100 {
  width: 100%;
}
.pf-m-width-50 {
  width: 50%;
}
.pf-m-width-66 {
  width: 66%;
}

.home-tabs {
  a.pf-c-nav__link.active {
    color: var(--pf-c-nav__link--m-current--Color);
    background-color: var(--pf-c-nav__link--m-current--BackgroundColor);
    border-bottom-width: var(
      --pf-c-nav__link--m-current--before--BorderBottomWidth
    );
  }
}

.font-size {
  &-7vw {
    font-size: 7vw !important;
  }
}
.cfp-guru-header{
  font-size: 30px;
  font-weight: bold;
}
